import { useCallback } from 'react';
import useVariation from 'hooks/useVariation/useVariation';

export const useTrackVariation = () => {
    const { namedVariation, variation } = useVariation();

    return useCallback(() => {
        const layer = {
            event: 'e_ABtest',
            test1name: 'CISS',
            test1variation: namedVariation,
        };

        if (variation !== undefined) {
            window.dataLayer.push(layer);
        }
    }, [namedVariation, variation]);
};
